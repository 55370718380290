import React from "react";

const Check = ({ color }) => {
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.17244 10.5C6.02012 10.5007 5.8736 10.4363 5.76423 10.3206L3.00939 7.40423C2.95448 7.34574 2.91067 7.27604 2.88045 7.19908C2.85024 7.12212 2.83421 7.03943 2.83329 6.95572C2.83143 6.78665 2.89121 6.62371 2.99948 6.50273C3.10774 6.38175 3.25562 6.31264 3.41059 6.31061C3.56556 6.30858 3.71492 6.3738 3.82581 6.49191L6.17477 8.97756L11.1736 3.68116C11.2847 3.56305 11.4342 3.4979 11.5892 3.50005C11.7443 3.5022 11.8923 3.57147 12.0005 3.69262C12.1088 3.81377 12.1685 3.97687 12.1665 4.14606C12.1646 4.31524 12.1011 4.47664 11.99 4.59475L6.58065 10.3206C6.47128 10.4363 6.32477 10.5007 6.17244 10.5Z"
        fill={color}
      />
    </svg>
  );
};

export default Check;
